import React, { FC, Fragment, useState } from 'react';

import { scrapersAPI } from 'api/scrapers';
import { Scraper } from 'api/scrapers/types';
import { resolveError } from 'api/utils';
import { notifications } from 'utils/notifications';
import { Modal } from 'components/common';
import { Button } from 'components/forms';
import { Editor } from 'components/editor';
import { ScraperTypeEnum } from 'types';
import { useIsPublisher } from 'store/user';
import { apiBase, publisherNeededTitle } from 'environment';

import { createFormData, OpenModal } from './ScrapersPage';

export const TableActions: FC<TableActionsProps> = ({ scraper, reloadData, openModal }) => {
  const [state, setState] = useState<TableActionsState>({
    isRunning: false,
    data: [],
  });
  const [visibleFileCount, setVisibleFileCount] = useState(10);
  const isPublisher = useIsPublisher();

  const runScraper = async () => {
    setState({ isRunning: true, data: [] });

    try {
      const data = await scrapersAPI.run(scraper._id);

      setState({ isRunning: false, data });
    } catch (err) {
      setState(oldState => ({
        ...oldState,
        isRunning: false,
      }));

      notifications.error(resolveError(err));
    }

    reloadData();
  };

  const allFiles = scraper.files?.filter(it => it.startsWith('http')).reverse() || [];
  const totFiles = allFiles.length || 0;
  const isApiScraper = [
    ScraperTypeEnum.API,
    ScraperTypeEnum.Facebook,
    ScraperTypeEnum.Instagram,
  ].includes(scraper.type);

  return (
    <div className="scrapers-table-actions">
      <Button
        onClick={ () => openModal(createFormData(scraper)) }
        title="Edit scraper"
        icon="edit"
      />

      <Button
        href={ `${apiBase}/scrapers/get-data/${scraper.name}` }
        target="_blank"
        title="Show data"
        icon="link"
      />

      <Modal
        isLoading={ state.isRunning }
        width={ 1000 }
        renderActions={ modal => (
          <Button
            onClick={ () => {
              if (isPublisher) {
                modal.open();
                runScraper();
              }
            } }
            disabled={ !isPublisher }
            title={ !isPublisher ? publisherNeededTitle : 'Retrieve data' }
            icon="reload"
          />
        ) }
      >
        <Editor
          language="javascript"
          code={ JSON.stringify(state.data, null, 2) }
          height={ 600 }
          readOnly
        />
      </Modal>

      { !isApiScraper && (
        <Modal
          width={ 1000 }
          className="files-modal"
          renderActions={ modal => (
            <Button
              onClick={ modal.open }
              title="See media files"
              icon="file"
            />
          ) }
        >
          <Fragment>
            <h3>Total: { totFiles } files</h3>

            <div className="files">
              { allFiles.map(src => (
                <a href={ src } key={ src } target="_blank" rel="noreferrer">
                  <img src={ src } alt="Scraper screenchot" title={ src } />
                </a>
              )) }
            </div>

            <div className="bottom">
              Shown <b>{ totFiles > visibleFileCount ? visibleFileCount : totFiles }</b> out of <b>{ totFiles }</b>

              { totFiles > visibleFileCount && (
                <button onClick={ () => setVisibleFileCount(visibleFileCount + 10) }>See more ++</button>
              ) }
            </div>
          </Fragment>
        </Modal>
      ) }
    </div>
  );
};

interface TableActionsProps {
  scraper: Scraper;
  reloadData: () => any;
  openModal: OpenModal;
}

interface TableActionsState {
  isRunning: boolean;
  data: any[];
}
