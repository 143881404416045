import React, { FC, useState, useEffect, useCallback, Fragment, useMemo, useRef } from 'react';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';

import { Spinner, Icon, Table, ColumnProps, Switch, Modal } from 'components/common';
import { Button } from 'components/forms';
import { scrapersAPI } from 'api/scrapers';
import { Scraper } from 'api/scrapers/types';
import { useSelectedOrganisation } from 'store/organisation';
import { notifications } from 'utils/notifications';
import { resolveError } from 'api/utils';
import { ScraperTypeEnum } from 'types';

import { useIsAdmin } from 'store/user';

import { adminNeededTitle } from 'environment';

import { ScraperConfigModal, ScraperFormData } from './confirg-modal';
import { ScraperType } from './ScraperType';
import { TableActions } from './TableActions';
import { defaultHandlers } from './default-handlers';

import './ScrapersPage.scss';

export const createFormData = ({
  _id,
  name,
  url,
  email,
  handler,
  type,
  interval,
  active,
  hookUrl,
  captureScreenshot,
  accessToken,
  instagramUsername,
}: Scraper): ScraperFormData => ({
  _id,
  scraperName: name,
  scraperURL: url,
  scraperEmail: email,
  scraperHandler: handler,
  scraperType: type,
  scraperInterval: interval,
  scraperActive: active,
  scraperHookUrl: hookUrl,
  captureScreenshot,
  accessToken: accessToken?._id,
  instagramUsername,
});

const getColumns = (
  updateScraper: ScraperUpdateFn,
  reloadData: () => any,
  openModal: OpenModal,
  isAdmin: boolean,
): ColumnProps<Scraper>[] => [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Page',
    dataIndex: 'url',
    render: (_, { url, accessToken, instagramUsername }) => {
      if (url) {
        return <a href={ url } target="_blank" rel="noopener noreferrer">{ url }</a>;
      }

      if (accessToken && instagramUsername) {
        return (
          <a
            href={ `https://www.instagram.com/${instagramUsername}/` }
            rel="noopener noreferrer"
            target="_blank"
          >
            Instagram account: <b>{instagramUsername}</b> | {accessToken.name}
          </a>
        );
      }

      if (accessToken) {
        return (
          <a
            href={ `https://www.facebook.com/${accessToken.id}/posts/` }
            rel="noopener noreferrer"
            target="_blank"
          >
            { accessToken.name } | { accessToken.id }
          </a>
        );
      }

      return 'N/A';
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: email => email || '-',
  },
  {
    title: 'Last updated',
    dataIndex: 'lastUpdated',
    align: 'center',
    width: 155,
    render: lastUpdated => (
      lastUpdated
        ? new Date(lastUpdated).toLocaleString('en-GB')
        : 'Never'
    ),
  },
  {
    title: 'Last success',
    dataIndex: 'lastSuccess',
    align: 'center',
    width: 155,
    render: lastSuccess => (
      lastSuccess
        ? new Date(lastSuccess).toLocaleString('en-GB')
        : 'Never'
    ),
  },
  {
    title: 'Interval',
    dataIndex: 'interval',
    className: 'interval',
    align: 'center',
    width: 95,
    render: interval => `${interval} min.`,
  },
  {
    title: 'Status',
    dataIndex: 'active',
    align: 'center',
    width: 70,
    render: (active, scraper) => (
      <Switch
        checked={ active }
        disabled={ !isAdmin }
        title={ !isAdmin ? adminNeededTitle : undefined }
        onChange={ newActiveStatus => updateScraper(scraper._id, {
          ...createFormData(scraper),
          scraperActive: newActiveStatus,
        }) }
      />
    ),
  },
  {
    title: 'Last call',
    dataIndex: 'error',
    className: 'last-call',
    align: 'center',
    render: (error, { status }) => {
      const success = status === 'success';

      if (!status) {
        return 'N/A';
      }

      return (
        <Fragment>
          <span className={ classnames('status-block', `type-${success ? 1 : 2}`) }>
            <Icon className="icon" type={ success ? 'check' : 'warning' } />
            { status }
          </span>

          { /* eslint-disable-next-line react/no-danger */ }
          { error && <div dangerouslySetInnerHTML={ { __html: error } } /> }
        </Fragment>
      );
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    className: 'type',
    align: 'center',
    width: 110,
    render: type => <ScraperType type={ type } />,
  },
  {
    title: 'Actions',
    className: 'actions',
    align: 'center',
    width: 160,
    render: (_, scraper) => (
      <TableActions
        scraper={ scraper }
        reloadData={ reloadData }
        openModal={ openModal }
      />
    ),
  },
];

const showDisabledKey = 'pro-show-disabled';
const defaultValues: Partial<ScraperFormData> = {
  scraperType: ScraperTypeEnum.Browser,
  scraperActive: false,
  captureScreenshot: false,
  scraperHandler: defaultHandlers[ScraperTypeEnum.Browser],
};

export const ScrapersPage: FC<RouteComponentProps> = () => {
  const [showDisabled, setShowDisabled] = useState(!!localStorage.getItem(showDisabledKey));
  const updateShowDisabled = useCallback(({ target }: CheckboxChangeEvent) => {
    setShowDisabled(target.checked);
    if (target.checked) {
      localStorage.setItem(showDisabledKey, 'true');
    } else {
      localStorage.removeItem(showDisabledKey);
    }
  }, []);
  const [{ isLoading, scrapers }, setState] = useState<ScrapersPageState>({
    isLoading: true,
    scrapers: [],
  });
  const filteredScrapers = useMemo(() => {
    if (showDisabled) {
      return scrapers;
    }

    return scrapers.filter(it => it.active);
  }, [scrapers, showDisabled]);
  const organisation = useSelectedOrganisation();
  const currentOrgId = organisation?._id;
  const openModalRef = useRef<Modal['open']>();

  const loadData = useCallback(async () => {
    if (currentOrgId) {
      setState(oldState => ({ ...oldState, isLoading: true }));
      const data = await scrapersAPI.getAll(currentOrgId);
      setState({ isLoading: false, scrapers: data });
    }
  }, [currentOrgId]);

  const updateScraper: ScraperUpdateFn = useCallback(async (scraperId, data) => {
    try {
      const message = await scrapersAPI.edit(scraperId, data);
      notifications.success({ message });

      loadData();
      return true;
    } catch (err) {
      const updateError = resolveError(err);
      notifications.error(updateError);
    }
  }, [loadData]);

  const createScraper: ScraperUpdateFn = useCallback(async (orgId, data) => {
    try {
      const message = await scrapersAPI.addNew(orgId, data);
      notifications.success({ message });

      loadData();
      return true;
    } catch (err) {
      const updateError = resolveError(err);
      notifications.error(updateError);
    }
  }, [loadData]);

  useEffect(() => { loadData(); }, [loadData]);

  const [formData, setFormData] = useState(defaultValues);
  const openModal: OpenModal = useCallback(data => {
    setFormData(data);

    if (openModalRef.current) {
      openModalRef.current();
    }
  }, []);
  const isAdmin = useIsAdmin();
  const tableColumns = useMemo(() => (
    getColumns(updateScraper, loadData, openModal, isAdmin)
  ), [updateScraper, loadData, openModal, isAdmin]);

  return (
    <div className="scrapers-page">
      <div className="show-disabled">
        <Checkbox checked={ showDisabled } onChange={ updateShowDisabled }>
          Show disabled
        </Checkbox>
      </div>

      <Table
        columns={ tableColumns }
        dataSource={ filteredScrapers }
        loading={ isLoading }
        rowKey="_id"
        size="small"
      />

      <ScraperConfigModal
        heading="Enter information below"
        onSubmit={ async data => {
          if (currentOrgId) {
            if (formData._id) {
              return updateScraper(formData._id, data);
            }

            return createScraper(currentOrgId, data);
          }
        } }
        initialData={ formData }
        renderActions={ modal => {
          openModalRef.current = modal.open;

          return (
            <Button
              type="primary"
              htmlType="submit"
              className="new-scraper"
              icon="plus"
              onClick={ () => openModal(defaultValues) }
              htmlDisabled={ !isAdmin }
              title={ !isAdmin ? adminNeededTitle : undefined }
              size="large"
            >
              Add new scraper
            </Button>
          );
        } }
      />

      <Spinner visible={ isLoading } />
    </div>
  );
};

interface ScrapersPageState {
  isLoading: boolean;
  scrapers: Scraper[];
}

export type OpenModal = (data: Partial<ScraperFormData>) => any;

export type ScraperUpdateFn = (scraperId: string, data: ScraperFormData) => Promise<boolean | undefined>
