import React, { FC, useMemo, lazy, Suspense } from 'react';
import { MonacoEditorProps } from 'react-monaco-editor';
import classnames from 'classnames';

import { Icon, Spinner } from 'components/common';
import { Button } from 'components/forms';

import './Editor.scss';

const MonacoEditor = lazy(() => import('react-monaco-editor'));

export const Editor: FC<EditorProps> = ({
  height = 100,
  language,
  code,
  readOnly,
  noMinimap,
  noLineHighlight,
  ...rest
}) => {
  const [isFullscreen, setFullscreen] = React.useState(false);
  const options = useMemo<MonacoEditorProps['options']>(() => ({
    readOnly,
    minimap: { enabled: noMinimap !== true },
    renderLineHighlight: noLineHighlight ? 'none' : 'line',
    scrollbar: {
      verticalScrollbarSize: 4,
    },
    lineNumbersMinChars: 4,
    lineDecorationsWidth: 0,
    automaticLayout: true,
  }), [noLineHighlight, noMinimap, readOnly]);

  return (
    <div className={ classnames('sec-editor', language, isFullscreen && 'fullscreen') }>
      <Button
        title={ isFullscreen ? 'Exit fullscreen' : 'Fullscreen' }
        onClick={ () => setFullscreen(!isFullscreen) }
        className="fullscreen-btn"
      >
        <Icon type={ isFullscreen ? 'fullscreenExit' : 'fullscreen' } size={ 20 } />
      </Button>
      <Suspense fallback={ <div className="loading"><Spinner /></div> }>
        <MonacoEditor
          height={ isFullscreen ? '100%' : height }
          width="100%"
          language={ language }
          theme="vs-dark"
          value={ code }
          options={ options }
          { ...rest }
        />
      </Suspense>
    </div>
  );
};

interface EditorProps extends MonacoEditorProps {
  code: string;
  language: string;
  height?: number;
  readOnly?: boolean;
  noMinimap?: boolean;
  noLineHighlight?: boolean;
}
