import React, { FC, Fragment, useMemo } from 'react';

import { Modal } from 'components/common';
import { Input, Form, Radio, RadioOption, SelectField, SelectOption, Button } from 'components/forms';
import { Editor } from 'components/editor';
import { ScraperTypeEnum } from 'types';
import { useIsAdmin } from 'store/user';
import { adminNeededTitle } from 'environment';

import { defaultHandlers, defaultHandlersArray } from '../default-handlers';
import { AccessToken } from 'store/organisation/types';
import { scrapersAPI } from 'api/scrapers';

export const scraperTypeLabels = {
  [ScraperTypeEnum.Browser]: 'Browser',
  [ScraperTypeEnum.API]: 'API',
  [ScraperTypeEnum.Facebook]: 'Facebook',
  [ScraperTypeEnum.Instagram]: 'Instagram',
  [ScraperTypeEnum.NodeJS]: 'NodeJS',
};

const scraperActiveOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
const captureScreenshot = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const ScraperConfigForm: FC<ScraperConfigFormProps> = ({ initialData, onSubmit, accessTokens, modalRef }) => {
  const fbPageTokens = useMemo(() => accessTokens?.filter(({ provider, type }) => (
    provider === 'facebook' && type === 'PAGE'
  )), [accessTokens]);
  const instaPageTokens = useMemo(() => accessTokens?.filter(({ provider, type }) => (
    provider === 'instagram' && type === 'PAGE'
  )), [accessTokens]);
  const fbAuthorized = !!fbPageTokens?.filter(({ error }) => !error).length;
  const instaAuthorized = !!instaPageTokens?.filter(({ error }) => !error).length;

  const scraperTypeOptions = useMemo((): RadioOption[] => [
    { value: ScraperTypeEnum.Browser, label: scraperTypeLabels[ScraperTypeEnum.Browser] },
    { value: ScraperTypeEnum.API, label: scraperTypeLabels[ScraperTypeEnum.API] },
    {
      value: ScraperTypeEnum.Facebook,
      label: scraperTypeLabels[ScraperTypeEnum.Facebook],
      ...(!fbAuthorized && {
        title: 'Please authorize Facebook in the Settings page',
        disabled: true,
      }),
    },
    {
      value: ScraperTypeEnum.Instagram,
      label: scraperTypeLabels[ScraperTypeEnum.Instagram],
      ...(!instaAuthorized && {
        title: 'Please authorize Instagram in the Settings page',
        disabled: true,
      }),
    },
    { value: ScraperTypeEnum.NodeJS, label: scraperTypeLabels[ScraperTypeEnum.NodeJS] },
  ], [fbAuthorized, instaAuthorized]);

  const fbAccessTokenOptions = useMemo((): SelectOption[] => {
    if (fbPageTokens?.length) {
      return fbPageTokens.map(({ _id, id, name }) => ({
        label: name,
        title: id,
        value: _id,
      }));
    }

    return [];
  }, [fbPageTokens]);
  const instaAccessTokenOptions = useMemo((): SelectOption[] => {
    if (instaPageTokens?.length) {
      return instaPageTokens.map(({ _id, id, name }) => ({
        label: name,
        title: id,
        value: _id,
      }));
    }

    return [];
  }, [instaPageTokens]);
  const isAdmin = useIsAdmin();

  const getRequiredFields = (selectedType: ScraperTypeEnum) => {
    if (selectedType === ScraperTypeEnum.Facebook) {
      return (
        <div className="graph-api-fields">
          <SelectField
            key="facebook"
            name="accessToken"
            label="Facebook page"
            options={ fbAccessTokenOptions }
            disabled={ !isAdmin }
            required
          />
        </div>
      );
    }

    if (selectedType === ScraperTypeEnum.Instagram) {
      return (
        <div className="graph-api-fields grid split">
          <SelectField
            key="instagram"
            name="accessToken"
            label="Instagram token"
            options={ instaAccessTokenOptions }
            disabled={ !isAdmin }
            required
          />
          <Input
            className="input-wrapper"
            label="Instagram username"
            name="instagramUsername"
            disabled={ !isAdmin }
            required
          />
        </div>
      );
    }

    if (selectedType !== ScraperTypeEnum.API) {
      return (
        <Input
          className="input-wrapper"
          label="URL"
          name="scraperURL"
          type="text"
          required={ selectedType === ScraperTypeEnum.Browser }
          disabled={ !isAdmin }
        />
      );
    }
  };

  const scraperId = initialData?._id;

  return (
    <Form
      onSubmit={
        async (data, _formApi, submitType) => {
          const success = await onSubmit(data);
          if (success && modalRef.current && submitType !== 'keyboard') {
            modalRef.current.close();
          }
        }
      }
      initialValues={ initialData }
      buttonLabel={ `${scraperId ? 'Update' : 'Create'} scraper` }
      renderExtraButtons={scraperId ? () => {
        return (
            <Button htmlType="button" size='large' type="ghost" onClick={() => scrapersAPI.run(scraperId)} async>
              Run Scraper
            </Button>
        );
      } : undefined}
      title={ !isAdmin ? adminNeededTitle : undefined }
      disabled={ !isAdmin }
      saveOnCtrlS
    >
      { ({ form, values: { scraperType, accessToken, scraperHandler } }) => {
        const isApiScraper = [
          ScraperTypeEnum.API,
          ScraperTypeEnum.Facebook,
          ScraperTypeEnum.Instagram,
        ].includes(scraperType);

        const facebookInvalid = accessToken && scraperType === ScraperTypeEnum.Facebook
          && !fbPageTokens.some(({ _id }) => _id === accessToken);
        const instaInvalid = accessToken && scraperType === ScraperTypeEnum.Instagram
          && !instaPageTokens.some(({ _id }) => _id === accessToken);

        if (facebookInvalid || instaInvalid) {
          form.change('accessToken', undefined);
        }

        return (
          <Fragment>
            <div className="grid split input-group">
              <div className="grid name-int">
                <Input
                  className="input-wrapper"
                  label="Scraper name"
                  name="scraperName"
                  disabled={ !isAdmin }
                  autoComplete="off"
                  type="text"
                  required
                />

                <Input
                  className="input-wrapper interval"
                  label="Interval (minutes)"
                  name="scraperInterval"
                  defaultValue="120"
                  disabled={ !isAdmin }
                  type="number"
                  min={ 30 }
                  required
                />

              </div>

              { getRequiredFields(scraperType) }
            </div>

            <div className="grid split input-group">
              <Input
                className="input-wrapper"
                label="Emal address"
                name="scraperEmail"
                disabled={ !isAdmin }
                type="email"
              />

              <Input
                className="input-wrapper hook-url"
                label="Hook URL"
                name="scraperHookUrl"
                disabled={ !isAdmin }
              />
            </div>

            <div className="grid choices input-group">
              <div className="field-block">
                <div className="label">
                  Scraper type:
                </div>

                <Radio
                  name="scraperType"
                  options={ scraperTypeOptions }
                  { ...(!isAdmin && { disabled: true }) }
                  onChange={ (value: ScraperTypeEnum) => {
                    const defaultHandler = value && defaultHandlers[value];

                    if (defaultHandlersArray.includes(scraperHandler)) {
                      form.change('scraperHandler', defaultHandler);
                    }
                  } }
                />
              </div>

              <div className="field-block">
                <div className="label">
                  Activated:
                </div>
                <Radio name="scraperActive" options={ scraperActiveOptions } disabled={ !isAdmin } />
              </div>

              { !isApiScraper && (
                <div className="field-block">
                  <div className="label">
                    Screenshot:
                  </div>
                  <Radio name="captureScreenshot" options={ captureScreenshot } disabled={ !isAdmin } />
                </div>
              ) }
            </div>

            <div className="label">
              Handler
            </div>

            <Editor
              code={ scraperHandler }
              language="javascript"
              height={ 400 }
              onChange={ value => form.change('scraperHandler', value) }
              readOnly={ !isAdmin }
              noMinimap
            />
          </Fragment>
        );
      } }
    </Form>
  );
};

export interface ScraperConfigFormProps {
  onSubmit: (data: ScraperFormData) => any;
  initialData?: Partial<ScraperFormData>;
  accessTokens: AccessToken[];
  modalRef: React.RefObject<Modal>;
}

export interface ScraperFormData {
  scraperName: string;
  scraperURL: string;
  scraperHandler: string;
  scraperType: ScraperTypeEnum;
  scraperInterval: number;
  scraperActive: boolean;
  captureScreenshot: boolean;
  scraperHookUrl: string;
  accessToken: string;
  scraperEmail?: string;
  instagramUsername?: string;
  _id?: string;
}
