import React, { FC, MouseEvent, useCallback, useState } from 'react';
import classnames from 'classnames';
import AntButton, { ButtonProps as AntButtonProps } from 'antd/lib/button';

import { IconType, Icon } from '../../common';

import './Button.scss';

export const Button: FC<ButtonProps> = ({
  className: passedClassName,
  type = 'default',
  icon,
  width,
  disabled,
  htmlDisabled,
  async: isAsync,
  onClick,
  loading,
  ...rest
}) => {
  const [internalLoading, setInternalLoading] = useState(false);
  const handleClick = useCallback(async (event: MouseEvent<HTMLElement>) => {
    if (isAsync) {
      setInternalLoading(true);
    }

    try {
      await onClick?.(event);
    } finally {
      if (isAsync) {
        setInternalLoading(false);
      }
    }
  }, [isAsync, onClick]);

  return (
    <AntButton
      className={ classnames('pro-button', disabled && 'disabled', passedClassName) }
      type={ type }
      icon={ icon && <Icon type={ icon } /> }
      style={ width ? { width } : undefined }
      loading={ loading || internalLoading }
      onClick={ onClick && handleClick }
      disabled={ htmlDisabled }
      { ...rest }
    />
  );
};

export interface ButtonProps extends Omit<AntButtonProps, 'icon'> {
  className?: string;
  icon?: IconType;
  width?: number;
  height?: number;
  htmlDisabled?: boolean;
  async?: boolean;
}
