export const fbAppId = String(process.env.FB_APP_ID);
export const googleClientId = String(process.env.GOOGLE_CLIENT_ID);

export const environment = (() => {
  const { host } = window.location;
  if (host.endsWith('portal.projekcijos.localhost')) {
    return 'development';
  }
  return 'production';
})();

export const apiBase = environment === 'development'
  ? 'https://api.projekcijos.localhost'
  : 'https://api.projekcijos.lt';

export const isDevelopment = environment === 'development';
export const isProduction = environment === 'production';

export const adminNeededTitle = 'Admin permissions required for this functionality';
export const publisherNeededTitle = 'Publisher permissions required for this functionality';

export const STORAGE_PREFIX = 'portal-';
export const cookieOptions = {
  authKey: `${STORAGE_PREFIX}authorization`,
  loggedInKey: `${STORAGE_PREFIX}logged-in`,
  lastUrlKey: `${STORAGE_PREFIX}last-url`,
};
