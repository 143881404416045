import React, { FC, useEffect, useRef } from 'react';

import { Modal, ModalProps } from 'components/common';
import { useSelectedOrganisation } from 'store/organisation';
import { useApiCallback } from 'hooks/api';
import { accessTokenAPI } from 'api/access-token';

import { ScraperConfigForm, ScraperConfigFormProps } from './ScraperConfigForm';

export const ScraperConfigModal: FC<ScraperConfigModalProps> = ({ initialData, onSubmit, ...rest }) => {
  const modalRef = useRef<Modal>(null);
  const organisation = useSelectedOrganisation();
  const {
    data: accessTokens,
    callback: loadAccessTokens,
    isLoading: isAccessTokensLoading,
  } = useApiCallback(accessTokenAPI.getAll, []);
  const orgId = organisation?._id;

  useEffect(() => {
    if (orgId) {
      loadAccessTokens(orgId);
    }
  }, [orgId, loadAccessTokens]);

  return (
    <Modal
      className="scraper-config"
      isLoading={ isAccessTokensLoading }
      ref={ modalRef }
      width={ 1080 }
      destroyOnClose
      { ...rest }
    >
      <ScraperConfigForm
        onSubmit={onSubmit}
        initialData={ initialData }
        accessTokens={accessTokens}
        modalRef={modalRef}
      />
    </Modal>
  );
};

type ScraperConfigModalProps = ModalProps & Pick<ScraperConfigFormProps, 'onSubmit' | 'initialData'>;
