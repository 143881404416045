import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { Form as FinalForm, FormProps as FinalFormProps, FormRenderProps } from 'react-final-form';
import { FormSubscription, AnyObject, FormApi } from 'final-form';
import createDecorator from 'final-form-focus';
import classnames from 'classnames';

import { notifications } from 'utils/notifications';
import { resolveError } from 'api/utils';

import { Spinner } from '../../common';
import { Button, ButtonProps } from '../button/Button';

import './Form.scss';

const decorators = [createDecorator()];

const defaultSubmit = (): any => null;
const defaultSubscription: FormSubscription = {
  valid: true,
  invalid: true,
  dirtySinceLastSubmit: true,
  submitFailed: true,
  submitting: true,
  submitError: true,
};

const defaultSubscriptionWithValues: FormSubscription = {
  ...defaultSubscription,
  values: true,
};

export const Form = <T extends AnyObject>({
  id,
  onSubmit = defaultSubmit,
  onSuccess,
  className: passedClassName,
  contentClassName,
  children,
  subscription = children instanceof Function ? defaultSubscriptionWithValues : defaultSubscription,
  buttonLabel,
  buttonSize = 'large',
  buttonWidth,
  renderExtraButtons,
  displaySpinner,
  initialValues,
  saveOnCtrlS,
  title,
  disabled,
  throwOnError,
  ...rest
}: FormProps<T>) => {
  const isCtrlS = useRef(false);
  const formRef = useRef<HTMLFormElement>(null);
  const handleSubmit: FinalFormProps<any>['onSubmit'] = useCallback(async (values, form) => {
    try {
      const response = await onSubmit(values, form, isCtrlS.current ? 'keyboard' : 'default');

      if (onSuccess) {
        onSuccess(response);
      }
    } catch (rawError) {
      const error = resolveError(rawError);

      if (error.fields) {
        return error.fields;
      }

      if (throwOnError) {
        throw error;
      }

      notifications.error(error);
    } finally {
      isCtrlS.current = false;
    }
  }, [onSubmit, onSuccess, throwOnError]);

  useEffect(() => {
    const $form = formRef.current;
    const keyboardHandler = (event: KeyboardEvent) => {
      if (event.key === 's' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();

        if (!isCtrlS.current) {
          isCtrlS.current = true;
          $form?.dispatchEvent(new Event('submit', { cancelable: true }));
        }
      }
    };

    if (saveOnCtrlS) {
      $form?.addEventListener('keydown', keyboardHandler);
      return () => $form?.removeEventListener('keydown', keyboardHandler);
    }
  }, [saveOnCtrlS]);

  return (
    <FinalForm<T>
      onSubmit={ handleSubmit }
      subscription={ subscription }
      decorators={ decorators as any }
      initialValues={ initialValues }
      { ...rest }
    >
      { (renderProps: FormRenderProps<T>) => {
        const {
          submitting,
          invalid,
          submitFailed,
          dirtySinceLastSubmit,
        } = renderProps;

        const innerContent = children instanceof Function
          ? children(renderProps)
          : children;

        const className = classnames(
          'pro-form',
          submitting && 'submitting',
          displaySpinner && 'with-spinner',
          passedClassName,
        );

        return (
          <form
            id={ id }
            onSubmit={ renderProps.handleSubmit }
            className={ className }
            title={ title }
            ref={ formRef }
            noValidate
          >
            <div className={ classnames('inner-content', contentClassName) }>
              { innerContent }
              { (buttonLabel || renderExtraButtons) && (
                <div className="button-container">
                  {buttonLabel && (
                    <Button
                      htmlType="submit"
                      disabled={ invalid && (!submitFailed || !dirtySinceLastSubmit) }
                      loading={ submitting }
                      type="primary"
                      size={ buttonSize }
                      width={ buttonWidth }
                      htmlDisabled={ disabled }
                    >
                      { buttonLabel }
                    </Button>
                  )}
                  {renderExtraButtons?.(renderProps)}
                </div>
              ) }
            </div>

            { displaySpinner && <Spinner visible={ submitting } /> }
          </form>
        );
      } }
    </FinalForm>
  );
};

export type SubmitType = 'default' | 'keyboard';

export interface FormProps<T = any> extends Omit<FinalFormProps<T>, 'onSubmit' | 'initialValues'> {
  id?: string;
  className?: string;
  contentClassName?: string;
  buttonLabel?: ReactNode;
  buttonSize?: ButtonProps['size'];
  buttonWidth?: number;
  displaySpinner?: boolean;
  initialValues?: Partial<T>;
  onSubmit?: (data: T, formApi: FormApi<T>, submitType: SubmitType) => any;
  onSuccess?: (response: any) => any;
  renderExtraButtons?: (props: FormRenderProps<T>) => ReactNode;
  saveOnCtrlS?: boolean;
  title?: string;
  disabled?: boolean;
  throwOnError?: boolean;
  // Some issue with the omit above, so we need to redeclare this
  children: FinalFormProps<T>['children']
}
